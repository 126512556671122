import {verify} from 'jsonwebtoken';
import {GetAuthDataProps} from "./types";
import {HeadersName} from "../../../../../common/enums";
import type {JWTPayload, SiteConfig} from "../../../../../common/types";


function decodeToken(token: string, secret: string): JWTPayload | null {
    try {
        return verify(token, secret) as JWTPayload;
    } catch (error) {
        return null;
    }
}

export function getAuthData({ctx}: GetAuthDataProps): SiteConfig['client']['auth'] {
    const {req, res} = ctx;
    const headerAuth = req?.headers['authorization'] || req?.headers[HeadersName.AuthBearerToken];
    const token = typeof headerAuth === 'string' && headerAuth.startsWith('Bearer ') ? headerAuth.slice(7) : undefined;
    const currentUrl = encodeURIComponent(req?.url || '/');

    if (!token) {
        return {
            isAuth: false,
        }
    }

    const secret = process.env.NEXT_SECRET_AUTH_SECRET_JWT_KEY || "";
    const payloadData = decodeToken(token, secret);

    if (!payloadData) {
        return {
            isAuth: false,
        }
    }


    return {
        isAuth: true,
        userId: payloadData.user_id?.toString(),
        roleName: payloadData.role_name,
        avatarUrl: payloadData.avatar_url,
    };
}
