/* eslint-disable no-console, react-hooks/exhaustive-deps -- I need this */
import {useEffect, useContext} from 'react';
import {SiteConfigContext} from "@uxu/design-system/providers/provider.configSite";

export function GoogleAdManagerHead(): null {
		const { config, setConfig } = useContext(SiteConfigContext);
		
		useEffect(() => {
				const script = document.createElement('script');
				script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
				script.async = true;
				
				function onScriptLoad(): void {
						console.log('The Google Ad Manager script has completed successfully');
						const updatedConfig = { ...config };
						updatedConfig.marketingToolsConfig = { ...updatedConfig.marketingToolsConfig, isLoadAdManager: true };
						setConfig(updatedConfig);
				}
				
				function onScriptError (error: unknown): void {
						console.error('Google Ad Manager script failed to load:', error)
				}
				
				script.addEventListener('load', onScriptLoad);
				script.addEventListener('error', onScriptError);
				
				document.head.appendChild(script);
				
				return () => {
						script.removeEventListener('load', onScriptLoad);
						script.removeEventListener('error', onScriptError);
						document.head.removeChild(script);
				};
		}, []);
		
		return null;
}
