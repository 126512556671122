export function setCookie(name: string, value: string): string {
    const sessionDurationInDays = parseInt(process.env.NEXT_PUBLIC_DEFAULT_SESSION_DURATION_IN_DAYS || "1", 10);
    const date = new Date();
    date.setTime(date.getTime() + (sessionDurationInDays * 24 * 60 * 60 * 1000));

    const expires = `expires=${date.toUTCString()}`;
    const secureFlag = process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 'Secure;' : '';

    const cookieString = `${name}=${value}; ${expires}; path=/; ${secureFlag} SameSite=Strict`;

    document.cookie = cookieString;

    return cookieString;
}
